import React, { ReactElement, useState } from 'react';
import Modal from '../modal/modal';
import './footer.scss';

export const Footer = (): ReactElement => {
  const [state, setstate] = useState({ showModal: false });

  const toggleModal = () => {
    setstate({ showModal: !state.showModal });
  };

  return (
    <div className="footer-wrapper">
      <p className="footer-text">
        Made with <span>&hearts;</span> by
      </p>
      <p className="footer-text">Fredrik Zeiner-Gundersen</p>
      <button
        type="button"
        className="footer-button footer-text"
        onClick={toggleModal}
      >
        About me
      </button>
      <Modal props={{ showModal: state.showModal, closeModal: toggleModal }} />
    </div>
  );
};
