import React, { ReactElement, useState } from 'react';
import { Link } from 'gatsby';

import MenuIcon from '../../assets/menu-icon.svg';
import CloseIcon from '../../assets/close-icon.svg';

import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import './menu.scss';

export const Menu = (): ReactElement => {
  const baseState = {
    showMenu: false,
    showModal: false,
  };

  const [state, setstate] = useState(baseState);

  const toggleMenu = () => {
    setstate({
      showModal: false,
      showMenu: !state.showMenu,
    });
  };

  const toggleModal = () => {
    setstate({
      showMenu: !state.showMenu,
      showModal: !state.showModal,
    });
  };

  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const menuType = isMobile ? 'mobile' : 'desktop';
  const activeStyle = state.showMenu ? 'visible' : '';

  const renderBackdrop = () => {
    if (isMobile) {
      return (
        <button
          type="button"
          aria-label="close menu"
          onClick={toggleMenu}
          className={`menu-background ${activeStyle}`}
        />
      );
    }
    return '';
  };

  const renderMenu = () => (
    <nav className={`menu-wrapper-${menuType} ${activeStyle}`}>
      {isMobile && <CloseIcon onClick={toggleMenu} className="close-icon" />}
      <ul className="menu-options-list">
        <li>
          <button
            className="menu-item-button menu-item"
            type="button"
            onClick={toggleModal}
          >
            About me
          </button>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      {/* <MenuIcon onClick={toggleMenu} className="menu-icon" /> */}
      {/* {renderMenu()} */}
      {renderBackdrop()}
    </>
  );
};
