import React, { ReactElement } from 'react';
import { Transition } from 'react-transition-group';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

import LinkedInIcon from '../../assets/linkedin.svg';
import GithubIcon from '../../assets/github.svg';
import CloseIcon from '../../assets/close-icon.svg';

import './modal.scss';
import { QueryData } from './modal.types';

const Modal: React.FC<any> = ({
  props: { showModal, closeModal },
}: any): ReactElement => {
  // const data: QueryData = useStaticQuery(graphql`
  //   query GetProfilePic {
  //     allImageSharp(filter: { original: { src: { regex: "/profile-pic/" } } }) {
  //       edges {
  //         node {
  //           gatsbyImageData(
  //             width: 200
  //             placeholder: BLURRED
  //             layout: CONSTRAINED
  //           )
  //         }
  //       }
  //     }
  //   }
  // `);

  const defaultStyle = {
    transition: `all 200ms ease-in-out`,
    opacity: 0,
  };

  const transitionStyles: { [key: string]: any } = {
    entered: { opacity: 1 },
    exited: { opacity: 0 },
  };

  return (
    <Transition
      in={showModal}
      timeout={{ exit: 200 }}
      unmountOnExit
      mountOnEnter
    >
      {styleState => (
        <section
          className="modal-wrapper"
          style={{ ...defaultStyle, ...transitionStyles[styleState] }}
        >
          <div className="modal">
            <header className="modal-header">
              <CloseIcon onClick={closeModal} className="modal-close-icon" />
              <article className="modal-profile-wrapper">
                <article className="modal-profile-pic-border">
                  <StaticImage
                    src="../../assets/profile-pic.jpeg"
                    alt="profile picture"
                    className="modal-profile-pic"
                  />
                </article>
                <h2 className="modal-heading">Fredrik Zeiner-Gundersen</h2>
              </article>
              <article className="modal-icons">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/fredrikzeinergundersen/"
                >
                  <LinkedInIcon className="icon" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/FredrikZeiner"
                >
                  <GithubIcon className="icon" />
                </a>
              </article>
              <section className="modal-about-me">
                <h2>About me</h2>
                <p>
                  Economist and programmer. I love learning and discovering new
                  technologies.
                </p>
              </section>
            </header>
          </div>
        </section>
      )}
    </Transition>
  );
};

export default Modal;
