import React, { ReactElement, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';

import './syntax-highlighting.scss';

import CopyIcon from '../../assets/copy-icon.svg';

const propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

type Props = PropTypes.InferProps<typeof propTypes>;

export const SyntaxHighlighting = ({
  language = 'js',
  code,
}: Props): ReactElement => {
  const node = useRef<HTMLElement | null>(null);
  const [active, setActive] = useState(false);
  useEffect(() => Prism.highlightAll());

  const copyCode = () => {
    if (node.current && !active) {
      navigator.clipboard.writeText(node.current?.innerText);
      setActive(true);
      setTimeout(() => setActive(false), 1000);
    }
  };

  return (
    <section className="highlight-section">
      <span className={`copy-text ${active ? 'fade-out' : ''}`}>Copied!</span>
      <button type="button" className="copy-button" onClick={copyCode}>
        <CopyIcon />
      </button>
      <pre className="gatsby-highlight">
        <code ref={node} className={`language-${language}`}>
          {code}
        </code>
      </pre>
    </section>
  );
};

SyntaxHighlighting.propTypes = propTypes;
