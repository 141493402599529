import React, { ReactElement } from 'react';
import { Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';

import BackIcon from '../../assets/arrow-back.svg';
import { Footer } from '../footer/footer';
import { Menu } from '../menu/menu';
import { SyntaxHighlighting } from '../syntax-highlighting/syntax-highlighting';
import './layout.scss';

interface Header {
  position: 'relative' | 'absolute';
  darkMode: true | false;
  backlink?: string;
  shouldRender?: boolean;
}

const Layout: React.FC<any> = ({
  title,
  children,
  header = {
    shouldRender: true,
    position: 'relative',
    darkMode: false,
  },
}: {
  title: string;
  children: ReactElement;
  header: Header;
}): ReactElement => {
  const { backlink, darkMode, position } = header;

  const titleElement = <h1>{title}</h1>;
  const backArrowElement = (
    <Link to="/">
      <BackIcon />
    </Link>
  );

  const headerElem = () =>
    header.shouldRender && (
      <div className="layout-wrapper" style={{ position }}>
        <header className={`layout-header ${darkMode ? 'dark' : 'light'}`}>
          {backlink ? backArrowElement : titleElement}
          <Menu />
        </header>
      </div>
    );

  return (
    <>
      <MDXProvider components={{ SyntaxHighlighting }}>
        {headerElem()}
        <main>{children}</main>
        <footer>
          <Footer />
        </footer>
      </MDXProvider>
    </>
  );
};

export default Layout;
